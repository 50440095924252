#fromiooo .formio-form .wizard-page {
  background: #fff !important;
  margin-left: 0px;
  padding: 2rem;
  /* height: 100%; */
  height: calc(100vh - 2.2rem);

  box-shadow: 0px -3px 12px #7278820f;
  overflow-y: auto;
}

#fromiooo .formio-form .wizard-page h1,
#fromiooo .formio-form .wizard-page h2,
#fromiooo .formio-form .wizard-page h3,
#fromiooo .formio-form .wizard-page h4,
#fromiooo .formio-form .wizard-page h5,
#fromiooo .formio-form .wizard-page h6 {
  margin: 0;
  padding: 0;
}

#fromiooo .formio-form {
  font-family: "Roboto", "sans-serif" !important;
}

#fromiooo .formio-form > div:first-child {
  display: flex;
  flex-direction: column;
  /* grid-template-columns: 20% 80%; */
  height: 100%;
}

.step {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: cream;
}

.v-stepper {
  position: relative;
  margin-top: -2px !important;
  margin-right: 10px !important;
}

/* regular step */
.step .circle {
  background-color: white;
  border: 2px solid #d4d8dd;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.step .line {
  top: 20px;
  left: 10px;
  height: 100%;
  position: absolute;
  margin-top: 8px;
  border-left: 2px solid #d4d8dd;
}

.step.completed .circle {
  visibility: visible;
  /* background-color: #3487f3; */
  background-image: url("assets/icons/check.svg") !important;
  border-color: #3487f3;
  padding: 4px;
}

.step.completed .line {
  border-left: 1px solid #e1e6ed;
  margin-top: 8px;
  /* background-image: url('assets/icons/unselected.svg') !important; */
}
/* how to draw a circl */
.step.active .circle {
  visibility: visible;
  border: 1px solid #3487f3;

  background-image: url("assets/icons/unselected.svg") !important;
}

.step.empty .circle {
  /* visibility: hidden; */
}

.step.empty .line {
  /*     visibility: hidden; */
  /*   height: 150%; */
  top: 0;
  height: 150%;
}

.step:last-child .line {
  border-left: 2px solid white;
  z-index: -1; /* behind the circle to completely hide */
}

#fromiooo > .formio-form > .wizard-nav-container .content {
  margin-left: 20px;
  display: inline-block;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  color: #39404b;
  background-color: #1e9d66 !important;
  font-size: 16px;
  line-height: 19px;
  font-family: Heebo;
  font-weight: 500;
  text-transform: capitalize !important;
}

#fromiooo .formio-form .wizard-nav-container .completed .content {
  color: #6b727d;
}

#fromiooo .formio-form .wizard-nav-container .active .content {
  color: #39404b;
  font-size: 16px;
  line-height: 19px;
  font-family: Heebo;
  font-weight: 500;
  text-transform: capitalize !important;
}

#fromiooo .formio-form .wizard-nav-container {
  background: #fff;
  display: none !important;
  /* width: 21%; */
  height: 100%;
  padding: 0px 20px;

  margin-left: 0;
  overflow-y: auto;
  /* position: fixed;
      left: 0;
      top: 0; */
}

#fromiooo .formio-form .formio-wizard-nav-container {
  position: absolute;
  right: 12px;
  bottom: 5px;
  background-color: #1e9d66 !important;
}
