@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@700&family=Inter:wght@600&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Rubik&display=swap");
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* File Uploader */

.fileUploader {
  min-width: 100% !important;
  /* height: 400px !important; */
  border: dashed 2px #3487f3 !important;
  background: #f8fbff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: "Roboto", "sans-serif" !important;
  margin-top: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.fileUploader.active {
  border: dashed 1px #2684ff !important;
}

.fileUploader .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fileUploader .fileWarpper {
  width: 128px;
  height: 128px;
  border-radius: 8px;
  background: #f5f6f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.fileUploader .fileWarpper img {
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 8px;
}

.fileUploader .fileWarpper.active {
  background: #1e9d66;
  color: #fff;
}
/* rotate animation */

.loading {
  animation: animName 2s linear infinite;
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.css-1ss2ei0-MuiContainer-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.rdw-editor-wrapper {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.rdw-editor-main {
  padding: 0 15px;
}
.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: #262d39 !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
}

.MuiStepLabel-labelContainer span {
  font-size: 0.875rem;
  color: #5b616b;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .MuiStepLabel-labelContainer span {
    font-size: 0.8rem;
    color: #5b616b;
  }
  .formio-choices.form-group,
  .formio-choices.formio-form-group {
    width: 66.5% !important;
  }
  /* .wizard-nav-container {

  padding: 30px 0px !important;

}  */
  .col-form-label {
    font-size: 13px !important;
  }
}
.choices__list--dropdown,
.choices__list[aria-expanded] {
  overflow: scroll !important;
  height: 200px !important;
}
.formio-component-datagrid {
  height: 350px !important;
}

.css-1vooibu-MuiSvgIcon-root {
  width: 1.5em !important;
  height: 1.5em !important;
}
.css-1cw4hi4 {
  user-select: none;
  width: 1.5em !important;
  height: 1.5em !important;
}

.formio-choices.form-group.is-open,
.formio-choices.formio-form-group {
  min-width: 480px !important;
  background-color: #18aee2 !important;
}

.formio-choices.form-group,
.formio-choices.formio-form-group {
  min-width: 220px !important;
}
.css-uvum4a-MuiTableCell-root {
  vertical-align: text-top !important;
}
.css-17ob23u-MuiTableCell-root {
  vertical-align: text-top !important;
}
/* .css-i4bv87-MuiSvgIcon-root {
  margin-top: 10px !important;
} */
.css-mkwsl5 {
  vertical-align: text-top;
}

.css-vnkopk-MuiStepLabel-iconContainer {
  align-items: baseline !important;
}

/* .css-155nyw6-MuiButton-endIcon {

  margin-top: -6px !important;
} */

@media screen and (min-width: 1920px) {
  .css-9sl4yc {
    width: 300px !important;
    height: 200px !important;
  }
}

.table__head {
  background: #f5f6f8;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.table__td {
  background: white;
  border-left: 1px solid #f0f0f2;
  font-weight: 600;
  color: black;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #f0f0f2;
  padding: 8px;
  /* -webkit-box-shadow: 9px 18px 30px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 9px 18px 30px 0px rgba(0, 0, 0, 0.27);
  box-shadow: 9px 18px 30px 0px rgba(0, 0, 0, 0.27); */
}
#customer table {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 25px;
  background: linear-gradient(
    to bottom,
    #e0e0e0 0%,
    #e1e1e1 10%,
    #e5e5e5 47%,
    #e7e7e7 100%
  );
  -webkit-border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  box-sizing: border-box;
}
#customers th {
  background-color: #404040;
  color: #ffffff;
  -webkit-box-shadow: inset 0px -5px 10px -5px #e0dcdc; /* Add a bottom shadow */
  -moz-box-shadow: inset 0px -5px 10px -5px #e0dcdc;
  box-shadow: inset 0px -5px 5px -5px #e0dcdc;
  z-index: 99999;
}

/* 
#customers tr:hover {
  background-color: #ddd;
} */

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f5f6f8;
  color: white;
}

.selected-item {
  background-color: #e0e0e0; /* Set your desired background color */
}

/* .container {
 background-color: #18aee2 !important;
  display: block;
  position: relative;

  border-radius: 8px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 120px !important;
} */

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1;
  margin-top: -8px !important;
  left: 0.5;
  margin-left: -10px !important;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 2px;
  border: 1.5px solid #c4cad3;
  cursor: pointer;
}
.checkmark-2 {
  position: absolute;
  top: 1;
  /* margin-top: -8px !important; */
  left: 41px;
  /* margin-left: -10px !important; */
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #3487f3;
  cursor: pointer;
}
.checkmark-3 {
  position: absolute;
  top: 1;
  /* margin-top: -8px !important; */
  left: 41px;
  /* margin-left: -10px !important; */
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #eaf3ff;
  border: 1px solid #3487f3;
}
.container input:checked ~ .checkmark-2 {
  background-color: #3487f3;
}
.container input:checked ~ .checkmark-3 {
  background-color: #8f939a;
}
.container input:checked ~ .checkmark-2:disabled {
  background-color: #454649;
}

/* When the checkbox is disabled, add a grey background */
.container input:disabled ~ .disabledcheckmark {
  background-color: #9c9c9c;
}

.container input:disabled:hover ~ .disabledcheckmark {
  border: 1px solid #9c9c9c;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkmark-2:after {
  content: "";
  position: absolute;
  display: none;
}
.checkmark-3:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
.container input:checked ~ .checkmark-2:after {
  display: block;
}
.container input:checked ~ .checkmark-3:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: solid #3487f3;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.container .checkmark-2:after {
  left: 4px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.container .checkmark-3:after {
  left: 5px;
  top: 2px;
  width: 7px;
  height: 10px;
  border: solid #fff;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-select {
  position: relative;
  font-family: Arial;
  background: transparent !important;
  min-width: 100px;
  border: none;
  outline: none;
  height: 28px;
  align-self: flex-end;
  margin-left: -12px;
}

.custom-select select {
  display: block;
  border: none;
  color: #5b616b;
  background: transparent !important;
  font-size: 0.875rem;
  align-self: flex-end;
}

.select-selected {
  border: none;
  outline: none;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #000;
  padding: 8px 16px;
  border: 0px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 16px !important;
}

.css-j6iqmb-MuiFormLabel-root-MuiInputLabel-root {
  color: #5b616b !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-completed {
  color: #262d39 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.css-2fdkz6.Mui-active {
  color: #262d39 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #e2e5e9;
}

.p-FieldError .Error {
  font-size: 12px !important;
}
#Field-expiryError {
  font-size: 12px !important;
}

.isosceles_triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 13.9px 13.9px 13.9px;
  border-color: transparent transparent #262d39 transparent;
}
.isosceles_triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 13.9px 13.9px 13.9px;
  border-color: transparent transparent #262d39 transparent;
}

.open-modal-button {
  font-size: 0px !important;
}
.open-modal-button table {
  display: none !important;
}

.open-modal-button::before {
  content: "Click to set value" !important;
  display: block !important;
  font-size: 1rem !important;
}

.checkbox-wrapper-1 *,
.checkbox-wrapper-1 ::after,
.checkbox-wrapper-1 ::before {
  box-sizing: border-box;
}
.checkbox-wrapper-1 [type="checkbox"].substituted {
  margin: 0;
  width: 0;
  height: 0;
  display: inline;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.checkbox-wrapper-1 [type="checkbox"].substituted + label:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  height: 1.15em;
  width: 1.15em;
  margin-right: 0.6em;
  color: rgba(0, 0, 0, 0.275);
  border: solid 0.06em;
  box-shadow:
    0 0 0.04em,
    0 0.06em 0.16em -0.03em inset,
    0 0 0 0.07em transparent inset;
  border-radius: 0.2em;
  background:
    url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>')
      no-repeat center,
    white;
  background-size: 0;
  will-change: color, border, background, background-size, box-shadow;
  transform: translate3d(0, 0, 0);
  transition:
    color 0.1s,
    border 0.1s,
    background 0.15s,
    box-shadow 0.1s;
}
.checkbox-wrapper-1 [type="checkbox"].substituted:enabled:active + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted:enabled
  + label:active:before {
  box-shadow:
    0 0 0.04em,
    0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(0, 0, 0, 0.1) inset;
  background-color: #f0f0f0;
}
.checkbox-wrapper-1 [type="checkbox"].substituted:checked + label:before {
  background-color: #3b99fc;
  background-size: 0.75em;
  color: rgba(0, 0, 0, 0.075);
}
.checkbox-wrapper-1
  [type="checkbox"].substituted:checked:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted:checked:enabled
  + label:active:before {
  background-color: #0a7ffb;
  color: rgba(0, 0, 0, 0.275);
}
.checkbox-wrapper-1 [type="checkbox"].substituted:focus + label:before {
  box-shadow:
    0 0 0.04em,
    0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(0, 0, 0, 0.1) inset,
    0 0 0 3.3px rgba(65, 159, 255, 0.55),
    0 0 0 5px rgba(65, 159, 255, 0.3);
}
.checkbox-wrapper-1 [type="checkbox"].substituted:focus:active + label:before,
.checkbox-wrapper-1 [type="checkbox"].substituted:focus + label:active:before {
  box-shadow:
    0 0 0.04em,
    0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(0, 0, 0, 0.1) inset,
    0 0 0 3.3px rgba(65, 159, 255, 0.55),
    0 0 0 5px rgba(65, 159, 255, 0.3);
}
.checkbox-wrapper-1 [type="checkbox"].substituted:disabled + label:before {
  opacity: 0.5;
}

.checkbox-wrapper-1 [type="checkbox"].substituted.dark + label:before {
  color: rgba(255, 255, 255, 0.275);
  background-color: #222;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="rgba(34, 34, 34, 0.999)" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>');
}
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:enabled
  + label:active:before {
  background-color: #444;
  box-shadow:
    0 0 0.04em,
    0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(255, 255, 255, 0.1) inset;
}
.checkbox-wrapper-1 [type="checkbox"].substituted.dark:checked + label:before {
  background-color: #a97035;
  color: rgba(255, 255, 255, 0.075);
}
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:checked:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:checked:enabled
  + label:active:before {
  background-color: #c68035;
  color: rgba(0, 0, 0, 0.275);
}
.checkbox-wrapper-1 [type="checkbox"].substituted + label {
  -webkit-user-select: none;
  user-select: none;
}
