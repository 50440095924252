.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-builder-group-header {
  padding: 0 !important;
}

.MuiListItemButton-root.Mui-selected {
  background-color: #f5f6f7 !important;
}

.MuiTablePagination-menuItem {
  text-align: center;
}

.MuiStepIcon-text {
  font-size: 0.8rem;
}

/* .MuiTableCell-body.MuiTableCell-sizeMedium {
  padding: 10px 0px 6px 15px !important;
} */
/* .css-vubbuv {

  margin-top: 10px !important;
} */

.new-loader{
  /* HTML: <div class="loader"></div> */
  margin-bottom: 1rem;
  --d:12px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: #3581E3;
  box-shadow: 
    calc(1*var(--d))      calc(0*var(--d))     0 0,
    calc(0.707*var(--d))  calc(0.707*var(--d)) 0 1px,
    calc(0*var(--d))      calc(1*var(--d))     0 1.2px,
    calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 1.2px,
    calc(-1*var(--d))     calc(0*var(--d))     0 1.6px,
    calc(-0.707*var(--d)) calc(-0.707*var(--d))0 1.8px,
    calc(0*var(--d))      calc(-1*var(--d))    0 2.5px;
  animation: l27 1s infinite steps(8);
}
@keyframes l27 {
  100% {transform: rotate(1turn)}

}
