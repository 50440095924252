.field-wrapper {
  display: block;
}

.form-group {
  max-width: 600px !important;
  margin: 0px auto 16px auto !important;
}

.col-form-label,
.form-check.checkbox lable.form-check-label span {
  font-size: 13px !important;
  padding-bottom: 4px !important;
  font-weight: 400 !important;
  color: #5b616b !important;
  padding-top: 0px !important;
}

.form-check-label span {
  font-size: 14px !important;
  padding-bottom: 0px !important;
}

input.form-control.form-control.input {
  border-radius: 4px 0px 0px 4px;
}

.form-radio.radio {
  margin-top: 0px !important;
}

.form-control {
  color: #0b1524;
  border: 1px solid #d4d8dd;
  font-weight: 400;
  font-size: 14px !important;
}

.form-control::placeholder {
  color: #babec6;
  font-weight: 400 !important;
}

.form-control::-ms-input-placeholder {
  color: #babec6;
  font-weight: 400 !important;
}

.col-form-label {
  font-size: 16px;
  font-weight: 500;
  color: #262d39;
}

.field-label {
  flex: 20 !important;
}

  .formio-select-autocomplete-input {
  display: none !important;
}

.formio-component {
    max-width: 600px;
    margin: 24px auto 0px;
}

.formio-component strong {
    color: #0B1524;
    font-weight: 600;
    letter-spacing: 0.2px;
}

.formio-component-modal-wrapper-editgrid {
  margin-top: 16px;
}

.editGrid-btn,
.open-modal-button {
  border: 1px solid #3487f3;
  background-color: #fff;
  color: #3487f3;
  width: fit-content;
  font-weight: 400;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-content {
  background: #f6f8f9;
}

.list-group-header,
.card-header {
  background-color: #F6F8F9;
}

.card-title,
.list-group-header .col-sm-3,
.list-group-header .col-sm-9,
.list-group-item.list-group-header .col-sm-9,
.list-group-header .col-sm-5 {
  font-weight: 500 !important;
  color: #0b1524 !important;
  font-size: 14px;
}

.list-group {
  margin: 16px 0px;
}

.editgrid-listgroup.list-group + button,
.editgrid-listgroup.list-group + button:hover,
.editgrid-listgroup.list-group + button:focus,
.editgrid-listgroup.list-group + button:active {
  background-color: unset;
  color: #3487f3;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.btn.editRow,
.btn.removeRow {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50% !important;
}

.btn.editRow {
  margin-right: 0.5rem !important;
  background-color: #ecf4ff !important;
  color: #3487f3 !important;
  padding: 6px 6px 6px 7px !important;
}

.list-group-item .col-sm-9 {
  color: #4e5b6e !important;
  font-weight: 400 !important;
  line-height: 2 !important;
}

input.form-check-input {
  width: 16px;
  height: 16px;
  margin-left: -1.5rem;
  margin-top: 0;
}

label.form-check-label {
  display: flex;
  align-items: center;
  line-height: 1.2;
}

.form-check-label span {
  color: #0b1524;
  font-size: 14px;
  font-weight: 400;
}

.form-check.checkbox {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.warning-message {
  padding: 0.5rem 1rem;
  background-color: #fffbf1;
  border-radius: 4px;
  color: #aa7200;
  margin-bottom: 1.25rem;
}

.warning-message p {
  margin-bottom: 0px;
}

.form-radio.radio {
  margin-top: 0.5rem;
}
.radio.form-check-inline label span {
  font-weight: 400;
  color: #0b1524;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-button {
  text-transform: unset;
  letter-spacing: 0;
  font-size: 1rem;
}

.list-group-item {
  padding: 1rem;
}

li.list-group-item.list-group-header {
  padding: 0.75rem 1.25rem;
}
