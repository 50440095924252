#fromioo .formio-form .wizard-page {
  background: #fff;
  width: 100% !important;
  /* margin-left: 10px; */
  /* padding: 20px 20px 0px 20px; */
  height: 100%;
  min-height: 70vh;
  /* height: calc(100vh - 4.2rem); */
  padding-left: 10px;

  box-shadow: 0px -3px 12px #7278820f;
  overflow-y: auto;
}
#fromioo .formio-form .wizard-page h1,
#fromioo .formio-form .wizard-page h2,
#fromioo .formio-form .wizard-page h3,
#fromioo .formio-form .wizard-page h4,
#fromioo .formio-form .wizard-page h5,
#fromioo .formio-form .wizard-page h6 {
  margin: 0;
  padding: 0;
}

#fromioo .formio-form {
  font-family: "Roboto", "sans-serif" !important;
  /* background-color: red; */
}

#fromioo .formio-form .wizard-page {
  /* padding-bottom: 65px; */
  width: 50vw !important;
  margin-top: 42px;
}

#fromioo .formio-form > div:first-child {
  display: grid;
  grid-template-columns: 20% 80%;
  height: 100%;
}

#fromioo .formio-form .wizard-nav-container .step {
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: cream;
}

#fromioo .formio-form .wizard-nav-container .v-stepper {
  position: relative;
  margin-top: -4px !important;
}

/* regular step */
#fromioo .formio-form .wizard-nav-container .step .circle {
  display: none !important;
}

#fromioo .formio-form .wizard-nav-container .step .line {
  top: 20px;
  left: 10px;
  height: 100%;
  position: absolute;
  margin-top: 8px;
  border-left: 2px solid #d4d8dd;
  display: none !important;
}

#fromioo .formio-form .wizard-nav-container .step.completed .circle {
  /* visibility: visible; */
  display: none !important;

  /* background-color: #3487f3; */
  background-image: url("assets/icons/check.svg") !important;
  border-color: #3487f3;
  padding: 4px;
}

#fromioo .formio-form .wizard-nav-container .step.completed .line {
  border-left: 1px solid #e1e6ed;
  display: none !important;
  margin-top: 8px;
  /* background-image: url('assets/icons/unselected.svg') !important; */
}
/* how to draw a circl */
#fromioo .formio-form .wizard-nav-container .step.active .circle {
  /* visibility: visible; */
  display: none !important;
  border: 1px solid #3487f3;

  background-image: url("assets/icons/unselected.svg") !important;
}

#fromioo .formio-form .wizard-nav-container {
  border-radius: 0px !important;
  margin-bottom: 20px !important;
  position: sticky !important;
  top: 0px;
  z-index: 1;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

#fromioo .formio-form .wizard-nav-container .step:first-child .content {
  margin-left: -20px;
  color: #262d39;
  background: #edf1f4;
  clip-path: polygon(95% 0%, 100% 50%, 95% 100%, 0% 100%, 0 50%, 0% 0%);
  padding: 2px 16px;
  font-size: 12px !important;
  line-height: normal;
  font-family: Heebo;
  font-weight: 400;
  text-transform: capitalize !important;
}

#fromioo
  .formio-form
  .wizard-nav-container
  .step:not(:first-child):not(:last-child)
  .content {
  clip-path: polygon(95% 0, 100% 50%, 95% 99%, 0% 100%, 5% 49%, 0% 0%);
  margin-left: -10px;
  color: #262d39;
  background: #edf1f4;
  padding: 2px 16px;
  font-size: 12px !important;
  line-height: normal;
  font-family: Heebo;
  font-weight: 400;
  text-transform: capitalize !important;
}

#fromioo .formio-form .wizard-nav-container .step:last-child .content {
  margin-left: -10px;
  color: #262d39;
  background: #edf1f4;
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 5% 51%, 0% 0%);
  padding: 2px 16px;
  font-size: 12px !important;
  line-height: normal;
  font-family: Heebo;
  font-weight: 400;
  text-transform: capitalize !important;
}

#fromioo .formio-form .wizard-nav-container .completed .content {
  color: #1c63d6;
  clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 8% 50%, 0% 0%);
  /* padding: 4px 16px; */
}

#fromioo .formio-form .wizard-nav-container .active .content {
  color: #3865a1 !important;
  padding: 4px 16px;
  background-color: #e9f2ff !important;
  font-size: 12px;
  line-height: normal;
  font-family: Heebo;
  font-weight: 600;
  text-transform: capitalize !important;
}

#fromioo .formio-form .wizard-nav-container {
  /* background: #fff; */
  display: flex !important;
  /* margin-top: 100px; */

  /* width: 21%; */
  /* height: 50%; */
  /* padding: 30px 20px; */

  /* margin-left: 0; */
  overflow-y: auto;
  position: absolute !important;
  top: 0;
  left: -10;
  max-height: 55px !important;
  /* position: fixed;
    left: 0;
    top: 0; */
}

.formio-form .formio-wizard-nav-container {
  position: absolute;
  right: 12px;
  bottom: 5px;
  background-color: #1e9d66 !important;
}
